export class NodeConfig {
    constructor(name, style, nodeType) {
        this.name = name;
        this.style = style;
        this.nodeType = nodeType;
    }
    getNodeType() {
        return this.nodeType;
    }
}
