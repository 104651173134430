import { Tag } from "@lezer/highlight";
import { Decoration, EditorView, keymap, ViewPlugin } from "@codemirror/view";
import { Prec } from "@codemirror/state";
import DecorationHolder from "./util/DecorationHolder";
import { syntaxTree } from "@codemirror/language";
import { NodeProp } from "@lezer/common";
import MyRange from "./util/Range";
import { EventAction } from "../feature/EventAction";
import { TodoMark } from "./todoListSimpleLanguage";
const Numbered = new Tag();
const NumberedMark = new Tag();
const InsertNodeProp = new NodeProp({ perNode: true });
const FullLengthNodeProp = new NodeProp({ perNode: true });
function getNumberedNode(view, main) {
    let node = syntaxTree(view.state).resolveInner(main.from, -1);
    while (node && node.name != 'Numbered') {
        node = node.parent;
    }
    return node;
}
const insertTab = (view) => {
    let main = view.state.selection.main;
    let node = getNumberedNode(view, main);
    if (!node)
        return false;
    const eventAction = new EventAction(view);
    eventAction.increaseIndent();
    return true;
};
const RemoveTab = (view) => {
    let main = view.state.selection.main;
    let node = getNumberedNode(view, main);
    if (!node)
        return false;
    const eventAction = new EventAction(view);
    eventAction.decreaseIndent();
    return true;
};
const InsertOnReturn = (view) => {
    let main = view.state.selection.main;
    let node = getNumberedNode(view, main);
    if (!node)
        return false;
    let insertString = node?.tree?.prop(InsertNodeProp);
    if (!insertString)
        return false;
    let fullLength = node?.tree?.prop(FullLengthNodeProp);
    if (!fullLength)
        fullLength = insertString.length;
    let changeRange, selectionRange, changeString;
    if (fullLength <= insertString.length) {
        const line = view.state.doc.lineAt(main.from);
        if (insertString.length == insertString.replace(/^\s+/, "").length) {
            changeString = '';
            selectionRange = new MyRange({ from: line.from, length: 0 });
        }
        else {
            changeString = insertString.substring(1);
            selectionRange = new MyRange({ from: line.from + insertString.length - 1, length: 0 });
        }
        changeRange = new MyRange({ from: line.from, to: line.to });
    }
    else {
        changeRange = new MyRange(main);
        selectionRange = new MyRange({ from: main.from + insertString.length + 1, length: 0 });
        changeString = "\n" + insertString;
    }
    view.dispatch({
        changes: [{
                from: changeRange.from,
                to: changeRange.to,
                insert: changeString
            }],
        selection: {
            anchor: selectionRange.from,
            head: selectionRange.to,
        }
    });
    return true;
};
let numbered = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.numbered(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.numbered(update.view);
            }
            numbered(view) {
                // @ts-ignore
                let items = [];
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (headerNode) => {
                            //console.log(headerNode.name, headerNode.from, headerNode.to);// use this to print out tags.
                            if (headerNode.name === "Numbered") {
                                // @ts-ignore
                                syntaxTree(view.state).iterate({
                                    from: headerNode.from,
                                    to: headerNode.to,
                                    enter: (node) => {
                                        if (node.name === "NumberedMark") {
                                            //console.log(headerNode.name, node.name, headerNode, node);
                                            items.push(new DecorationHolder(Decoration.mark({ class: "numbered_mark" }), node.from, node.to));
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets);
            }
        }, {
            decorations: v => v.decorations
        }),
        EditorView.baseTheme({
            '.numbered_mark': {
                fontWeight: 'bold',
            }
        }),
        Prec.high(keymap.of([
            { key: "Enter", run: InsertOnReturn },
            { key: "Tab", run: insertTab, shift: RemoveTab },
        ]))];
};
const regExp = /^(?<numbered>(?<numberedMark>[^\S\n]*([\d\w#]+\.|\(?[\d\w#]+\))(?<todoMark>[^\S\n]\[[ xX]])?[^\S\n])[^\n]*)$/gm;
export let numberedListSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Numbered", style: Numbered },
                { name: "NumberedMark", style: NumberedMark },
                { name: "TodoMark", style: TodoMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    let numberMarkChildren = [];
                    if (match.groups.todoMark) {
                        numberMarkChildren.push(context.createTree({
                            name: "TodoMark",
                            range: context.buildRange({
                                from: match.index + match.groups.numberedMark.length - match.groups.todoMark.length,
                                length: match.groups.todoMark.length
                            })
                        }));
                    }
                    return context.createTree({
                        name: "Numbered", children: [context.createTree({
                                name: "NumberedMark",
                                range: context.buildRange({
                                    from: match.index,
                                    length: match.groups.numberedMark.length
                                }),
                                children: numberMarkChildren
                            })],
                        range: context.buildRange({
                            from: match.index,
                            length: match.groups.numbered.length
                        }),
                        props: [
                            [InsertNodeProp, match.groups.numberedMark.replace(/\[[xX]]/, "[ ]")],
                            [FullLengthNodeProp, match.groups.numbered.length]
                        ]
                    });
                });
            }
        }],
    language: [
        numbered()
    ]
};
