import { Tag } from "@lezer/highlight";
import { isCursorInRange } from "./util/isCursorInRange";
import { Decoration, EditorView, ViewPlugin } from "@codemirror/view";
import { syntaxTree } from "@codemirror/language";
const HorizontalRule = new Tag();
let horizontalRule = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.horizontalRule(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.horizontalRule(update.view);
            }
            horizontalRule(view) {
                let widgets = [];
                let invisible = Decoration.replace({});
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (node) => {
                            if (node.name === "HorizontalRule" && !isCursorInRange(view.state, [node.from, node.to])) {
                                let deco = Decoration.line({
                                    class: "HorizontalRule"
                                });
                                let line = view.state.doc.lineAt(node.from);
                                widgets.push(deco.range(line.from));
                                widgets.push(invisible.range(node.from, node.to));
                            }
                        }
                    });
                }
                return Decoration.set(widgets);
            }
        }, {
            decorations: v => v.decorations
        }),
        EditorView.baseTheme({
            ".HorizontalRule": {
                position: "relative",
                bottom: "9px",
                "border-bottom": "var(--divider-bar-color, black) solid 3px;"
            }
        })];
};
const regExp = /^(?<match>(([*\-_])[^\S\n]*){3,})$/gm;
export let horizontalRuleSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "HorizontalRule", style: HorizontalRule },
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    return context.createTree({
                        name: "HorizontalRule",
                        range: context.buildRange({ from: match.index, length: match.groups.match.length })
                    });
                });
            }
        }],
    language: [
        horizontalRule()
    ]
};
