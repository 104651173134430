import { Tag } from "@lezer/highlight";
import { Decoration, ViewPlugin, WidgetType } from "@codemirror/view";
import DecorationHolder from "./util/DecorationHolder";
import { syntaxTree } from "@codemirror/language";
import { isCursorInRange } from "./util/isCursorInRange";
export const TodoMark = new Tag();
class CheckMarkWidget extends WidgetType {
    constructor(checked, changeMarkdown) {
        super();
        this.checked = checked;
        this.changeMarkdown = changeMarkdown;
    }
    toDOM() {
        const inputElement = document.createElement('input');
        inputElement.type = "checkbox";
        inputElement.checked = this.checked;
        inputElement.onclick = ev => {
            console.log("test");
            ev.preventDefault();
            this.changeMarkdown(ev);
        };
        const spanElement = document.createElement("span");
        spanElement.appendChild(inputElement);
        return spanElement;
    }
}
let todo = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.bullets(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.bullets(update.view);
            }
            bullets(view) {
                // @ts-ignore
                let items = [];
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (node) => {
                            //console.log(headerNode.name, headerNode.from, headerNode.to);// use this to print out tags.
                            if (node.name === "TodoMark") {
                                // @ts-ignore
                                //console.log(headerNode.name, node.name, headerNode, node);
                                if (!isCursorInRange(view.state, [node.from, node.to])) {
                                    let string = view.state.doc.sliceString(node.from, node.to);
                                    let checked = !string.startsWith("[ ]");
                                    const update = {
                                        changes: {
                                            from: node.from,
                                            to: node.to,
                                            insert: checked ? "[ ] " : "[x] "
                                        }
                                    };
                                    items.push(new DecorationHolder(Decoration.replace({
                                        widget: new CheckMarkWidget(checked, () => {
                                            view.dispatch(update);
                                        })
                                    }), node.from, node.to));
                                }
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets);
            }
        }, {
            decorations: v => v.decorations
        })];
};
export let todoListSimpleLanguage = {
    language: [
        todo()
    ]
};
