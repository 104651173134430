import { Tag } from "@lezer/highlight";
import { buildSimpleViewPlugin } from "./util/buildSimpleViewPlugin";
import { buildSimpleRegexParser } from "./util/buildSimpleRegexParser";
const Strikethrough = new Tag();
const StrikethroughMark = new Tag();
export let strikethrough = () => {
    return [buildSimpleViewPlugin({
            tagName: "s"
        }, "Strikethrough", "StrikethroughMark")];
};
export let strikethroughSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Strikethrough", style: Strikethrough },
                { name: "StrikethroughMark", style: StrikethroughMark }
            ],
            parser: buildSimpleRegexParser("~~", "Strikethrough", "StrikethroughMark")
        }],
    language: [
        strikethrough()
    ]
};
