import { buildSimpleViewPlugin } from "./util/buildSimpleViewPlugin";
import { Tag } from "@lezer/highlight";
import { buildSimpleRegexParser } from "./util/buildSimpleRegexParser";
export const StrongEmphasis = new Tag();
const StrongEmphasisMark = new Tag();
let strongEmphasis = () => {
    return [buildSimpleViewPlugin({
            tagName: "strong"
        }, "StrongEmphasis", "StrongEmphasisMark")];
};
export let StrongEmphasisSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "StrongEmphasis", style: StrongEmphasis },
                { name: "StrongEmphasisMark", style: StrongEmphasisMark }
            ],
            parser: buildSimpleRegexParser("\\*\\*", "StrongEmphasis", "StrongEmphasisMark")
        }],
    language: [
        strongEmphasis()
    ]
};
