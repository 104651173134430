import { combineConfig, Transaction, StateField, StateEffect, Facet, Annotation, ChangeSet, ChangeDesc, EditorSelection } from "@codemirror/state";
import { EditorView, keymap } from "@codemirror/view";
const fromHistory = Annotation.define();
/// Transaction annotation that will prevent that transaction from
/// being combined with other transactions in the undo history. Given
/// `"before"`, it'll prevent merging with previous transactions. With
/// `"after"`, subsequent transactions won't be combined with this
/// one. With `"full"`, the transaction is isolated on both sides.
export const isolateHistory = Annotation.define();
/// This facet provides a way to register functions that, given a
/// transaction, provide a set of effects that the history should
/// store when inverting the transaction. This can be used to
/// integrate some kinds of effects in the history, so that they can
/// be undoneHistoryEvents (and redone again).
export const invertedEffects = Facet.define();
const historyConfig = Facet.define({
    combine(configs) {
        return combineConfig(configs, {
            minDepth: 100,
            newGroupDelay: 500,
            joinToEvent: (_t, isAdjacent) => isAdjacent,
        }, {
            minDepth: Math.max,
            newGroupDelay: Math.min,
            joinToEvent: (a, b) => (tr, adj) => a(tr, adj) || b(tr, adj)
        });
    }
});
function changeEnd(changes) {
    let end = 0;
    changes.iterChangedRanges((_, to) => end = to);
    return end;
}
const historyField_ = StateField.define({
    create() {
        return HistoryState.empty;
    },
    update(historyState, tr) {
        let config = tr.state.facet(historyConfig);
        let fromHist = tr.annotation(fromHistory);
        if (fromHist) { // to prevent undo or redo from getting added wrong.
            if (fromHist.side === 2 /* BranchName.Clear */) {
                return HistoryState.empty;
            }
            let selection = tr.docChanged ? EditorSelection.single(changeEnd(tr.changes)) : undefined;
            let item = HistoryEvent.fromTransaction(tr, selection), from = fromHist.side;
            let other = from == 0 /* BranchName.Done */ ? historyState.undoneHistoryEvents : historyState.doneHistoryEvents;
            if (item)
                other = updateHistoryEvents(other, other.length, config.minDepth, item);
            else
                other = addSelection(other, tr.startState.selection);
            return new HistoryState(from == 0 /* BranchName.Done */ ? fromHist.rest : other, from == 0 /* BranchName.Done */ ? other : fromHist.rest);
        }
        let isolate = tr.annotation(isolateHistory);
        if (isolate == "full" || isolate == "before")
            historyState = historyState.isolate();
        if (tr.annotation(Transaction.addToHistory) === false)
            return !tr.changes.empty ? historyState.addMapping(tr.changes.desc) : historyState;
        let event = HistoryEvent.fromTransaction(tr);
        let time = tr.annotation(Transaction.time), userEvent = tr.annotation(Transaction.userEvent);
        if (event)
            historyState = historyState.addChanges(event, time, userEvent, config, tr);
        if (isolate == "full" || isolate == "after")
            historyState = historyState.isolate();
        return historyState;
    },
    toJSON(value) {
        return { doneHistoryEvents: value.doneHistoryEvents.map(e => e.toJSON()), undoneHistoryEvents: value.undoneHistoryEvents.map(e => e.toJSON()) };
    },
    fromJSON(json) {
        return new HistoryState(json.doneHistoryEvents.map(HistoryEvent.fromJSON), json.undoneHistoryEvents.map(HistoryEvent.fromJSON));
    }
});
/// Create a history extension with the given configuration.
export function history(config = {}) {
    return [
        historyField_,
        historyConfig.of(config),
        EditorView.domEventHandlers({
            beforeinput(e, view) {
                let command = e.inputType == "historyUndo" ? undo : e.inputType == "historyRedo" ? redo : null;
                if (!command)
                    return false;
                e.preventDefault();
                return command(view);
            }
        }),
        keymap.of(historyKeymap)
    ];
}
/// The state field used to store the history data. Should probably
/// only be used when you want to
/// [serialize](#state.EditorState.toJSON) or
/// [deserialize](#state.EditorState^fromJSON) state objects in a way
/// that preserves history.
//export const historyField = historyField_ as StateField<unknown>
function cmd(side) {
    return function ({ state, dispatch }) {
        if (state.readOnly)
            return false;
        let historyState = state.field(historyField_, false);
        if (!historyState)
            return false;
        let tr;
        if (side === 2 /* BranchName.Clear */) {
            tr = historyState.clear(state);
        }
        else {
            tr = historyState.pop(side, state);
        }
        if (!tr)
            return false;
        dispatch(tr);
        return true;
    };
}
/// Undo a single group of history events. Returns false if no group
/// was available.
export const undo = cmd(0 /* BranchName.Done */);
/// Redo a group of history events. Returns false if no group was
/// available.
export const redo = cmd(1 /* BranchName.Undone */);
// History events store groups of changes or effects that need to be
// undoneHistoryEvents/redone together.
class HistoryEvent {
    constructor(
    // The changes in this event. Normal events hold at least one
    // change or effect. But it may be necessary to store selection
    // events before the first change, in which case a special type of
    // instance is created which doesn't hold any changes, with
    // changes == startSelection == undefined
    changes, 
    // The effects associated with this event
    effects, 
    // Accumulated mapping (from addToHistory==false) that should be
    // applied to events below this one.
    mapped, 
    // The selection before this event
    startSelection, 
    // Stores selection changes after this event, to be used for
    // selection undo/redo.
    selectionsAfter) {
        this.changes = changes;
        this.effects = effects;
        this.mapped = mapped;
        this.startSelection = startSelection;
        this.selectionsAfter = selectionsAfter;
    }
    setSelAfter(after) {
        return new HistoryEvent(this.changes, this.effects, this.mapped, this.startSelection, after);
    }
    toJSON() {
        return {
            changes: this.changes?.toJSON(),
            mapped: this.mapped?.toJSON(),
            startSelection: this.startSelection?.toJSON(),
            selectionsAfter: this.selectionsAfter.map(s => s.toJSON())
        };
    }
    static fromJSON(json) {
        return new HistoryEvent(json.changes && ChangeSet.fromJSON(json.changes), [], json.mapped && ChangeDesc.fromJSON(json.mapped), json.startSelection && EditorSelection.fromJSON(json.startSelection), json.selectionsAfter.map(EditorSelection.fromJSON));
    }
    // This does not check `addToHistory` and such, it assumes the
    // transaction needs to be converted to an item. Returns null when
    // there are no changes or effects in the transaction.
    static fromTransaction(tr, selection) {
        let effects = emptyArray;
        for (let invert of tr.startState.facet(invertedEffects)) {
            let result = invert(tr);
            if (result.length)
                effects = effects.concat(result);
        }
        if (!effects.length && tr.changes.empty)
            return null;
        return new HistoryEvent(tr.changes.invert(tr.startState.doc), effects, undefined, selection || tr.startState.selection, emptyArray);
    }
    static selection(selections) {
        return new HistoryEvent(undefined, emptyArray, undefined, undefined, selections);
    }
}
function updateHistoryEvents(historyEvents, to, maxLen, newEvent) {
    let start = to + 1 > maxLen + 20 ? to - maxLen - 1 : 0;
    let newBranch = historyEvents.slice(start, to);
    newBranch.push(newEvent);
    return newBranch;
}
function isAdjacent(a, b) {
    let ranges = [], isAdjacent = false;
    a.iterChangedRanges((f, t) => ranges.push(f, t));
    b.iterChangedRanges((_f, _t, f, t) => {
        for (let i = 0; i < ranges.length;) {
            let from = ranges[i++], to = ranges[i++];
            if (t >= from && f <= to)
                isAdjacent = true;
        }
    });
    return isAdjacent;
}
function concat(a, b) {
    return !a.length ? b : !b.length ? a : a.concat(b);
}
const emptyArray = [];
const MaxSelectionsPerEvent = 200;
function addSelection(branch, selection) {
    if (!branch.length) {
        return [HistoryEvent.selection([selection])];
    }
    else {
        let lastEvent = branch[branch.length - 1];
        let sels = lastEvent.selectionsAfter.slice(Math.max(0, lastEvent.selectionsAfter.length - MaxSelectionsPerEvent));
        if (sels.length && sels[sels.length - 1].eq(selection))
            return branch;
        sels.push(selection);
        return updateHistoryEvents(branch, branch.length - 1, 1e9, lastEvent.setSelAfter(sels));
    }
}
// Add a mapping to the top event in the given branch. If this maps
// away all the changes and effects in that item, drop it and
// propagate the mapping to the next item.
function addMappingToBranch(branch, mapping) {
    if (!branch.length)
        return branch;
    let length = branch.length, selections = emptyArray;
    while (length) {
        let event = mapEvent(branch[length - 1], mapping, selections);
        if (event.changes && !event.changes.empty || event.effects.length) { // Event survived mapping
            let result = branch.slice(0, length);
            result[length - 1] = event;
            return result;
        }
        else { // Drop this event, since there's no changes or effects left
            mapping = event.mapped;
            length--;
            selections = event.selectionsAfter;
        }
    }
    return selections.length ? [HistoryEvent.selection(selections)] : emptyArray;
}
function mapEvent(event, mapping, extraSelections) {
    let selections = concat(event.selectionsAfter.length ? event.selectionsAfter.map(s => s.map(mapping)) : emptyArray, extraSelections);
    // Change-less events don't store mappings (they are always the last event in a branch)
    if (!event.changes)
        return HistoryEvent.selection(selections);
    let mappedChanges = event.changes.map(mapping), before = mapping.mapDesc(event.changes, true);
    let fullMapping = event.mapped ? event.mapped.composeDesc(before) : before;
    return new HistoryEvent(mappedChanges, StateEffect.mapEffects(event.effects, mapping), fullMapping, event.startSelection.map(before), selections);
}
const joinableUserEvent = /^(input\.type|delete)($|\.)/;
class HistoryState {
    constructor(doneHistoryEvents, undoneHistoryEvents, prevTime = 0, prevUserEvent = undefined) {
        this.doneHistoryEvents = doneHistoryEvents;
        this.undoneHistoryEvents = undoneHistoryEvents;
        this.prevTime = prevTime;
        this.prevUserEvent = prevUserEvent;
    }
    isolate() {
        return this.prevTime ? new HistoryState(this.doneHistoryEvents, this.undoneHistoryEvents) : this;
    }
    addChanges(event, time, userEvent, config, tr) {
        let done = this.doneHistoryEvents, lastEvent = done[done.length - 1];
        if (lastEvent && lastEvent.changes && !lastEvent.changes.empty && event.changes &&
            (!userEvent || joinableUserEvent.test(userEvent)) &&
            ((!lastEvent.selectionsAfter.length &&
                time - this.prevTime < config.newGroupDelay &&
                config.joinToEvent(tr, isAdjacent(lastEvent.changes, event.changes))) ||
                // For compose (but not compose.start) events, always join with previous event
                userEvent == "input.type.compose")) {
            done = updateHistoryEvents(done, done.length - 1, config.minDepth, new HistoryEvent(event.changes.compose(lastEvent.changes), concat(event.effects, lastEvent.effects), lastEvent.mapped, lastEvent.startSelection, emptyArray));
        }
        else {
            done = updateHistoryEvents(done, done.length, config.minDepth, event);
        }
        return new HistoryState(done, emptyArray, time, userEvent);
    }
    addMapping(mapping) {
        return new HistoryState(addMappingToBranch(this.doneHistoryEvents, mapping), addMappingToBranch(this.undoneHistoryEvents, mapping), this.prevTime, this.prevUserEvent);
    }
    pop(branchName, editorState) {
        let historyEvents = branchName == 0 /* BranchName.Done */ ? this.doneHistoryEvents : this.undoneHistoryEvents;
        if (historyEvents.length == 0)
            return null;
        let historyEvent = historyEvents[historyEvents.length - 1];
        if (!historyEvent.changes) {
            return null;
        }
        else {
            let restHistoryEvents = historyEvents.length == 1 ? emptyArray : historyEvents.slice(0, historyEvents.length - 1);
            if (historyEvent.mapped)
                restHistoryEvents = addMappingToBranch(restHistoryEvents, historyEvent.mapped);
            return editorState.update({
                changes: historyEvent.changes,
                selection: historyEvent.startSelection,
                effects: historyEvent.effects,
                annotations: fromHistory.of({ side: branchName, rest: restHistoryEvents }),
                filter: false,
                userEvent: branchName == 0 /* BranchName.Done */ ? "undo" : "redo",
                scrollIntoView: true
            });
        }
    }
    clear(editorState) {
        return editorState.update({
            annotations: fromHistory.of({ side: 2 /* BranchName.Clear */, rest: [] }),
            userEvent: "clear",
        });
    }
}
HistoryState.empty = new HistoryState(emptyArray, emptyArray);
/// Default key bindings for the undo history.
///
/// - Mod-z: [`undo`](#commands.undo).
/// - Mod-y (Mod-Shift-z on macOS) + Ctrl-Shift-z on Linux: [`redo`](#commands.redo).
/// - Mod-u: [`undoSelection`](#commands.undoSelection).
/// - Alt-u (Mod-Shift-u on macOS): [`redoSelection`](#commands.redoSelection).
export const historyKeymap = [
    { key: "Mod-z", run: undo, preventDefault: true },
    { key: "Mod-Z", run: redo, preventDefault: true },
];
