import { NodeSet, NodeType, Parser } from "@lezer/common";
import { Tag } from "@lezer/highlight";
import { SimplePartialParse } from "./simplePartialParse";
import { NodeConfig } from "./nodeConfig";
export const Mark = new Tag();
const Document = new Tag();
const defaultTypes = [
    { name: "Document", style: Document }
];
export class SimpleParser extends Parser {
    constructor(extensions) {
        super();
        this.nodes = new Map();
        this.parsers = new Array();
        let nodeArray = [];
        this.nodeTypeId = 1;
        const addToNodes = (value) => {
            const nodeType = NodeType.define({
                id: this.nodeTypeId++,
                name: value.name,
                top: value.top,
            });
            this.nodes.set(value.name, new NodeConfig(value.name, value.style, nodeType));
            nodeArray.push(nodeType);
        };
        this.nodeSet = new NodeSet(nodeArray);
        defaultTypes.forEach(addToNodes);
        extensions.forEach(extension => {
            extension.defineNodes.forEach(addToNodes);
            this.parsers.push(extension.parser);
        });
    }
    createParse(input, fragments, ranges) {
        return new SimplePartialParse(this, input, fragments, ranges);
    }
    getNodes() {
        return this.nodes;
    }
    getParsers() {
        return this.parsers;
    }
    getNodeSet() {
        return this.nodeSet;
    }
}
