import { ParserContext } from "./parserContext";
import { ObjectTree } from "./ObjectTree";
import Range from "../combind/util/Range";
export class SimplePartialParse {
    constructor(parent, input, fragments, ranges) {
        this.children = [];
        this.parent = parent;
        this.input = input;
        this.fragments = fragments;
        this.ranges = ranges;
        this.rangeNumber = 0;
    }
    advance() {
        let maxRange = { from: 0, to: 0 };
        for (let range of this.ranges) {
            maxRange = SimplePartialParse.maxMin(maxRange, range);
            const stringToParse = this.input.read(range.from, range.to);
            for (const parser of this.parent.getParsers()) {
                const trees = parser(new ParserContext({ string: stringToParse, nodes: this.parent.getNodes() }));
                for (const tree of trees) {
                    this.children.push(tree);
                }
            }
        }
        if (maxRange.from === maxRange.to)
            return;
        let tree = new ObjectTree(this.parent.getNodes().get("Document").getNodeType(), new Range(maxRange), this.children);
        return tree.toTree();
    }
    stopAt(pos) {
    }
    static maxMin(maxRange, range) {
        return { from: Math.min(maxRange.from, range.from), to: Math.max(maxRange.to, range.to) };
    }
}
