import { Tag } from "@lezer/highlight";
import { buildSimpleViewPlugin } from "./util/buildSimpleViewPlugin";
import { EditorView } from "@codemirror/view";
import { AttributeNodeProp } from "./util/attributeNodeProp";
const BracketedSpan = new Tag();
const BracketedSpanMark = new Tag();
const BracketedSpanInfo = new Tag();
export let bracketedSpan = () => {
    return [buildSimpleViewPlugin({
            tagName: "span"
        }, "BracketedSpan", "BracketedSpanMark"),
        EditorView.baseTheme({
            ".mark": { "background-color": "var(--highlight-color, yellow)" },
            ".underline": { "text-decoration-line": "underline" },
            ".smallcaps": { "font-variant-caps": "small-caps" }
        })];
};
const regExp = /(?<markStart>\[)[^\]]*(?<markEnd>]{(?<info>[^}]*)})/g;
export let bracketedSpanSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "BracketedSpan", style: BracketedSpan },
                { name: "BracketedSpanMark", style: BracketedSpanMark },
                { name: "BracketedSpanInfo", style: BracketedSpanInfo }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    return context.createTree({
                        name: "BracketedSpan",
                        children: [
                            context.createTree({
                                name: "BracketedSpanMark",
                                range: context.buildRange({
                                    from: match.index,
                                    length: match.groups.markStart.length
                                })
                            }),
                            context.createTree({
                                name: "BracketedSpanMark",
                                range: context.buildRange({
                                    from: match.index + match[0].length - match.groups.markEnd.length,
                                    length: match.groups.markEnd.length
                                })
                            }),
                        ],
                        range: context.buildRange({
                            from: match.index,
                            length: match[0].length,
                        }),
                        props: [[AttributeNodeProp, match.groups.info]]
                    });
                });
            }
        }],
    language: [
        bracketedSpan()
    ]
};
