import { Tag } from "@lezer/highlight";
import { buildSimpleViewPlugin } from "./util/buildSimpleViewPlugin";
import { EditorView } from "@codemirror/view";
const HTMLComment = new Tag();
const HTMLCommentMark = new Tag();
export let htmlComment = () => {
    return [buildSimpleViewPlugin({
            class: "htmlcomment"
        }, "HTMLComment", "HTMLCommentMark"),
        EditorView.baseTheme({
            ".htmlcomment": { "color": "var(--comment-color, gray)" }
        })];
};
const regExp = /(?<preObj>^|\s)(?<mark1><!--).*(?<mark2>-->)/g;
export let htmlCommentSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "HTMLComment", style: HTMLComment },
                { name: "HTMLCommentMark", style: HTMLCommentMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    return context.createTree({
                        name: "HTMLComment",
                        children: [
                            context.createTree({
                                name: "HTMLCommentMark",
                                range: context.buildRange({
                                    from: match.index + match.groups.preObj.length,
                                    length: match.groups.mark1.length
                                })
                            }),
                            context.createTree({
                                name: "HTMLCommentMark",
                                range: context.buildRange({
                                    from: match.index + match[0].length - match.groups.mark2.length,
                                    length: match.groups.mark2.length
                                })
                            })
                        ],
                        range: context.buildRange({
                            from: match.index + match.groups.preObj.length,
                            length: match[0].length - match.groups.preObj.length
                        })
                    });
                });
            }
        }],
    language: [
        htmlComment()
    ]
};
