import { Tag } from "@lezer/highlight";
import { Decoration, EditorView, ViewPlugin, WidgetType } from "@codemirror/view";
import { NodeProp } from "@lezer/common";
import DecorationHolder from "./util/DecorationHolder";
import { syntaxTree } from "@codemirror/language";
import { AttributeNodeProp, AttributeNodePropToModifiedDecorationSpec } from "./util/attributeNodeProp";
const InlineImage = new Tag();
const InlineImageMark = new Tag();
const InlineImageUrl = new NodeProp({ perNode: true });
const InlineImageInline = new NodeProp({ perNode: true });
const InlineImageCaption = new NodeProp({ perNode: true });
class ImageWidgetType extends WidgetType {
    constructor({ url, caption, attributes, setCursor }) {
        super();
        this.url = url;
        this.caption = caption;
        this.attributes = attributes;
        this.setCursor = setCursor;
    }
}
class ImageInlineWidget extends ImageWidgetType {
    // noinspection JSUnusedGlobalSymbols
    toDOM() {
        const figure = document.createElement('figure');
        figure.onclick = ev => {
            ev.preventDefault();
            this.setCursor(ev);
        };
        const image = document.createElement("img");
        image.src = this.url;
        image.id = this.attributes.id;
        image.className = this.attributes.class;
        for (const key in this.attributes.attributes) {
            // @ts-ignore
            image[key] = this.attributes.attributes[key];
        }
        figure.appendChild(image);
        const figcaption = document.createElement("figcaption");
        figcaption.textContent = this.caption;
        figure.appendChild(figcaption);
        return figure;
    }
}
class ImageFigureWidget extends ImageWidgetType {
    // noinspection JSUnusedGlobalSymbols
    toDOM() {
        const image = document.createElement('img');
        image.onclick = ev => {
            ev.preventDefault();
            this.setCursor(ev);
        };
        image.src = this.url;
        image.title = this.caption;
        image.id = this.attributes.id;
        image.className = this.attributes.class;
        for (const key in this.attributes.attributes) {
            // @ts-ignore
            image[key] = this.attributes.attributes[key];
        }
        return image;
    }
}
let inlineImage = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.headings(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.headings(update.view);
            }
            headings(view) {
                // @ts-ignore
                let items = [];
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (outerNode) => {
                            if (outerNode.name === "InlineImage") {
                                const url = outerNode?.tree?.prop(InlineImageUrl);
                                const inline = outerNode?.tree?.prop(InlineImageInline);
                                const caption = outerNode?.tree?.prop(InlineImageCaption);
                                const attributes = new AttributeNodePropToModifiedDecorationSpec(outerNode?.tree?.prop(AttributeNodeProp)).buildObject();
                                const placeCursor = {
                                    selection: {
                                        anchor: outerNode.from + 2,
                                        head: outerNode.from + 2,
                                    },
                                    scrollIntoView: true
                                };
                                const obj = {
                                    url,
                                    caption,
                                    attributes,
                                    setCursor: () => view.dispatch(placeCursor)
                                };
                                items.push(new DecorationHolder(Decoration.widget({
                                    widget: inline ? new ImageFigureWidget(obj) : new ImageInlineWidget(obj),
                                    side: 1
                                }), outerNode.from, outerNode.to, true));
                                items.push(new DecorationHolder(Decoration.mark({ class: "cm-mark image-text" }), outerNode.from, outerNode.to));
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets, true);
            }
        }, {
            decorations: v => v.decorations
        }),
        EditorView.baseTheme({
            ".image-text": { "color": "var(--comment-color, gray)" }
        })];
};
const regExp = /(?<mark1>!\[)(?<caption>[^\]]*)(?<mark2>]\((?<url>[^)]+)\)({(?<attributes>[^}]+)})?(?<inline>\\)?)/gm;
export let InlineImageSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "InlineImage", style: InlineImage },
                { name: "InlineImageMark", style: InlineImageMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    return context.createTree({
                        name: "InlineImage",
                        children: [
                            context.createTree({
                                name: "InlineImageMark",
                                range: context.buildRange({
                                    from: match.index,
                                    length: match.groups.mark1.length
                                })
                            }),
                            context.createTree({
                                name: "InlineImageMark",
                                range: context.buildRange({
                                    from: match.index + match[0].length - (match.groups.mark2.length),
                                    length: match.groups.mark2.length
                                })
                            })
                        ],
                        range: context.buildRange({
                            from: match.index, length: match[0].length
                        }),
                        props: [
                            [InlineImageUrl, match.groups.url],
                            [InlineImageInline, !!match.groups.inline],
                            [InlineImageCaption, match.groups.caption],
                            [AttributeNodeProp, match.groups.attributes]
                        ]
                    });
                });
            }
        }],
    language: [
        inlineImage()
    ]
};
