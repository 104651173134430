import { Tree } from "@lezer/common";
export class ObjectTree {
    constructor(nodeType, range, children, props) {
        //console.log("ObjectTree", nodeType.name, inputRange, children);
        this.nodeType = nodeType;
        this.range = range;
        this.children = children;
        this.props = props;
    }
    getRange() {
        return { from: this.range.from, to: this.range.to };
    }
    buildBuffer() {
        if (this.children.length == 0)
            return [this.nodeType.id, this.range.from, this.range.to, 4];
        let itemArray = [];
        let sum = 0;
        for (const child of this.children) {
            const childArray = child.buildBuffer();
            sum += childArray[childArray.length - 1];
            itemArray = itemArray.concat(childArray);
        }
        //console.log(this.nodeType.name, sum+4);
        return itemArray.concat([this.nodeType.id, this.range.from, this.range.to, sum + 4]);
    }
    toTree() {
        if (this.children.length == 0)
            return new Tree(this.nodeType, [], [], this.range.to - this.range.from, this.props);
        let itemArray = [];
        let itemPosition = [];
        for (const child of this.children) {
            itemArray.push(child.toTree());
            itemPosition.push(child.range.from - this.range.from);
        }
        //console.log("toTree", itemArray, itemPosition, this.range.to - this.range.from);
        return new Tree(this.nodeType, itemArray, itemPosition, this.range.to - this.range.from, this.props);
    }
}
