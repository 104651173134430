import { Tag } from "@lezer/highlight";
import { Decoration, EditorView, ViewPlugin } from "@codemirror/view";
import DecorationHolder from "./util/DecorationHolder";
import { syntaxTree } from "@codemirror/language";
const Definition = new Tag();
const DefinitionTerm = new Tag();
const DefinitionMark = new Tag();
let definition = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.definitions(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.definitions(update.view);
            }
            definitions(view) {
                // @ts-ignore
                let items = [];
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (headerNode) => {
                            //console.log(headerNode.name, headerNode.from, headerNode.to);// use this to print out tags.
                            if (headerNode.name === "Definition") {
                                // @ts-ignore
                                syntaxTree(view.state).iterate({
                                    from: headerNode.from,
                                    to: headerNode.to,
                                    enter: (node) => {
                                        if (node.name === "DefinitionMark") {
                                            items.push(new DecorationHolder(Decoration.mark({ class: "definition_mark" }), node.from, node.to));
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets);
            }
        }, {
            decorations: v => v.decorations
        }),
        EditorView.baseTheme({
            '.definition_mark': {
                fontWeight: 'bold',
            }
        })];
};
const regExp = /^(?<defTerm>[^\n]+)((\n[^\S\n]+~[^\n]+)+|(\n:[^\S\n]+[^\n]+)+)$/gm;
const regExp2 = /^(?<defMark>[^\S\n]+~|:[^\S\n]+)(?<defString>[^\n]+)$/gm;
export let definitionListSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Definition", style: Definition },
                { name: "DefinitionTerm", style: DefinitionTerm },
                { name: "DefinitionMark", style: DefinitionMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    const children = [
                        context.createTree({
                            name: "DefinitionTerm",
                            range: context.buildRange({
                                from: match.index, length: match.groups.defTerm.length
                            })
                        })
                    ];
                    let value;
                    while (value = regExp2.exec(match[0])) {
                        children.push(context.createTree({
                            name: "DefinitionMark",
                            range: context.buildRange({
                                from: match.index + value.index, length: value.groups.defMark.length
                            })
                        }));
                    }
                    return context.createTree({
                        name: "Definition",
                        children: children,
                        range: context.buildRange({
                            from: match.index, length: match[0].length
                        })
                    });
                });
            }
        }],
    language: [
        definition()
    ]
};
