import { Decoration, ViewPlugin } from "@codemirror/view";
import DecorationHolder from "./DecorationHolder";
import { syntaxTree } from "@codemirror/language";
import { AttributeNodeProp, AttributeNodePropToModifiedDecorationSpec } from "./attributeNodeProp";
export function buildSimpleViewPlugin(decorationSpec, nodeName, nodeMarkName) {
    return ViewPlugin.fromClass(class {
        constructor(view) {
            this.decorations = this.headings(view);
        }
        update(update) {
            if (update.docChanged || update.viewportChanged || update.selectionSet)
                this.decorations = this.headings(update.view);
        }
        headings(view) {
            // @ts-ignore
            let items = [];
            let invisible = Decoration.mark({ class: "cm-mark" });
            for (let { from, to } of view.visibleRanges) {
                syntaxTree(view.state).iterate({
                    from, to,
                    enter: (outerNode) => {
                        if (outerNode.name === nodeName) {
                            // @ts-ignore
                            let deco = Decoration.mark(new AttributeNodePropToModifiedDecorationSpec(outerNode?.tree?.prop(AttributeNodeProp), decorationSpec).buildObject());
                            items.push(new DecorationHolder(deco, outerNode.from, outerNode.to));
                            syntaxTree(view.state).iterate({
                                from: outerNode.from,
                                to: outerNode.to,
                                enter: (node) => {
                                    if (node.name === nodeMarkName) {
                                        items.push(new DecorationHolder(invisible, node.from, node.to));
                                    }
                                }
                            });
                        }
                    }
                });
            }
            let widgets = [];
            items.sort((a, b) => a.compareTo(b))
                .forEach(value => widgets.push(value.buildRange()));
            return Decoration.set(widgets);
        }
    }, {
        decorations: v => v.decorations
    });
}
