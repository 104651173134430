import { dropCursor, EditorView, highlightActiveLine, highlightActiveLineGutter, keymap, lineNumbers, showPanel } from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";
import { Compartment, EditorState, Facet } from "@codemirror/state";
import { highlightSelectionMatches } from "@codemirror/search";
import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { bracketMatching, foldGutter } from "@codemirror/language";
import { history } from "./plugin/feature/history";
import pandocMarkdownLanguage from "./plugin/language/pandocMarkdownLanguage";
import { search, searchKeymap } from "./plugin/feature/search";
import { spellcheck } from "./plugin/feature/spellcheck";
import { gotoKeymap } from "./plugin/feature/goto";
import { menubar } from "./plugin/feature/menubar";
import { allowTab } from "./plugin/feature/keyMap";
let baseThemeSpec = {
    ".cm-line:not(.cm-activeLine) .cm-mark, .cm-line:not(.cm-activeLine) .cm-mark *": {
        fontSize: "0em !important"
    },
    ".cm-mark, .cm-mark *": {
        transition: "font-size 1.5s"
    },
    ".cm-content": {
        caretColor: "var(--markdown-editor-caret-color, black)",
        paddingBottom: "50vh"
    },
    "&light .cm-gutters": {
        backgroundColor: "var(--markdown-editor-gutter-background-color, #f5f5f5)",
        color: "var(--markdown-editor-gutter-color, #6c6c6c)",
        borderRight: "1px solid var(--markdown-editor-gutter-border-color, #ddd)"
    },
    "&dark .cm-gutters": {
        backgroundColor: "var(--markdown-editor-gutter-background-color, #333338)",
        color: "var(--markdown-editor-gutter-color, #ccc)",
        borderRight: "1px solid var(--markdown-editor-gutter-border-color, #ddd)"
    },
    "&light .cm-activeLineGutter": {
        backgroundColor: "var(--markdown-editor-active-line-gutter-background-color, #e2f2ff)"
    },
    "&dark .cm-activeLineGutter": {
        backgroundColor: "var(--markdown-editor-active-line-gutter-background-color, #222227)"
    },
    "&light .cm-activeLine": {
        backgroundColor: "var(--markdown-editor-active-line-gutter-background-color, #e2f2ff)"
    },
    "&dark .cm-activeLine": {
        backgroundColor: "var(--markdown-editor-active-line-gutter-background-color, #222227)"
    },
    "&light .cm-panel": {
        color: "var(--markdown-editor-gutter-color, #ccc)",
        backgroundColor: "var(--markdown-editor-gutter-background-color, #333338)",
    },
    "&dark .cm-panel": {
        color: "var(--markdown-editor-gutter-color, #ccc)",
        backgroundColor: "var(--markdown-editor-gutter-background-color, #333338)",
    },
    "&light .cm-panels-bottom": {
        borderTop: "1px solid var(--markdown-editor-gutter-border-color, #ccc)"
    },
    "&dark .cm-panels-bottom": {
        borderTop: "1px solid var(--markdown-editor-gutter-border-color, #ccc)"
    },
    //todo need remove when i rewrite link stuff.
    "&light .cm-link": {
        color: "var(--text-color)"
    },
    "&dark .cm-link": {
        color: "var(--text-color)"
    },
    "&light .cm-link:link": {
        color: "var(--text-link-color)"
    },
    "&light .cm-link:visited": {
        color: "var(--text-link-visited-color)"
    },
    "&light .cm-link:visited:hover": {
        color: "var(--text-link-hover-visited-color)"
    },
    "&light .cm-link:hover": {
        color: "var(--text-link-hover-color)"
    },
    "&dark .cm-link:link": {
        color: "var(--text-link-color)"
    },
    "&dark .cm-link:visited": {
        color: "var(--text-link-visited-color)"
    },
    "&dark .cm-link:visited:hover": {
        color: "var(--text-link-hover-visited-color)"
    },
    "&dark .cm-link:hover": {
        color: "var(--text-link-hover-color)"
    },
    "& .cm-textfield": {
        backgroundColor: "var(--input-background-color, white)",
        borderColor: "var(--input-border-color, silver)",
        color: "var(--input-text-color, black)",
        fontSize: "100%"
    },
    "& .cm-button": {
        backgroundColor: "var(--button-background-color, white)",
        borderColor: "var(--button-border-color, rgb(136, 136, 136))",
        color: "var(--button-text-color, black)",
        backgroundImage: "none",
        fontSize: "100%"
    },
    "& .cm-panels-top": {
        borderBottomColor: "var(--markdown-editor-gutter-border-color, rgb(221, 221, 221))"
    },
    "& .cm-panels": {
        zIndex: "1"
    },
    "& .material-icons": {
        fontFamily: "'Material Icons',monospace",
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "var(--icon-font-size, 16px)",
        lineHeight: "1",
        letterSpacing: "normal",
        textTransform: "none",
        display: "inline-block",
        whiteSpace: "nowrap",
        wordWrap: "normal",
        direction: "ltr",
        "-webkit-font-feature-settings": 'liga',
        "-webkit-font-smoothing": "antialiased"
    },
    "& button:active": {
        backgroundColor: "inherit",
    },
    "& .rotated": {
        transform: "rotate(-180deg)",
    },
    "& a": {
        color: "var(--text-color, black)"
    },
    "& a:link": {
        color: "var(--text-link-color, blue)"
    },
    "& a:visited": {
        color: "var(--text-link-visited-color, violet)"
    },
    "& a:visited:hover": {
        color: "var(--text-link-hover-visited-color, blueviolet)"
    },
    "& a:hover": {
        color: "var(--text-link-hover-color, darkblue)"
    },
    "& select": {
        padding: "5px 8px",
        margin: "4px 2px",
        boxSizing: "border-box",
        backgroundColor: "var(--input-background-color, white)",
        border: "2px solid var(--input-border-color, gray)",
        color: "var(--input-text-color, black)"
    },
    "& select:focus": {
        border: "2px solid var(--input-focus-border-color, black)",
        outline: "none",
    }
};
class MarkdownEditor {
    // @ts-ignore
    constructor({ parent, root, events = {
        [MarkdownEditor.events.blur]: [],
        [MarkdownEditor.events.update]: [],
        [MarkdownEditor.events.focus]: [],
        [MarkdownEditor.events.save]: []
    } }) {
        // @ts-ignore
        this.events = {
            [MarkdownEditor.events.blur]: [],
            [MarkdownEditor.events.update]: [],
            [MarkdownEditor.events.focus]: [],
            [MarkdownEditor.events.save]: []
        };
        if (!parent) {
            throw "MarkdownEditor: no parent defined";
        }
        this.parent = parent;
        if (events[MarkdownEditor.events.blur])
            this.addEvents(MarkdownEditor.events.blur, ...events[MarkdownEditor.events.blur]);
        if (events[MarkdownEditor.events.update])
            this.addEvents(MarkdownEditor.events.update, ...events[MarkdownEditor.events.update]);
        if (events[MarkdownEditor.events.focus])
            this.addEvents(MarkdownEditor.events.focus, ...events[MarkdownEditor.events.focus]);
        if (events[MarkdownEditor.events.save])
            this.addEvents(MarkdownEditor.events.save, ...events[MarkdownEditor.events.save]);
        this.tabSize = new Compartment;
        this.theme = new Compartment;
        this.name = new Compartment;
        let wordCount = Facet.define({ combine: value => value[0] });
        this.wordCount = wordCount;
        this.makeViewConfig = (doc = "") => {
            return {
                doc: doc,
                extensions: [
                    // @ts-ignore
                    //interactions ---
                    keymap.of(defaultKeymap.concat(allowTab).concat(searchKeymap).concat(gotoKeymap)),
                    this.tabSize.of(EditorState.tabSize.of(4)),
                    menubar({ saveEvent: (view) => {
                            this.events[MarkdownEditor.events.save].forEach(value => value({ doc: view.state.doc.toString() }));
                        } }),
                    bracketMatching(),
                    closeBrackets(),
                    autocompletion(),
                    history(),
                    search({ top: true }),
                    //editorWindow ---
                    lineNumbers(),
                    foldGutter(),
                    highlightActiveLine(),
                    highlightActiveLineGutter(),
                    highlightSelectionMatches(),
                    dropCursor(),
                    //word count
                    wordCount.compute(["doc"], state => {
                        let doc = state.doc;
                        let count = 0, iter = doc.iter();
                        while (!iter.next().done) {
                            let inWord = false;
                            for (let i = 0; i < iter.value.length; i++) {
                                let word = /[\w']/.test(iter.value[i]);
                                if (word && !inWord)
                                    count++;
                                inWord = word;
                            }
                        }
                        return count;
                    }),
                    //bottom bar
                    showPanel.of(function (view) {
                        let dom = document.createElement("div");
                        dom.textContent = "Word count: ";
                        let countNode = document.createElement("span");
                        dom.appendChild(countNode);
                        countNode.textContent = `${view.state.facet(wordCount)}`;
                        return {
                            dom,
                            update(update) {
                                if (update.docChanged) {
                                    countNode.textContent = `${view.state.facet(wordCount)}`;
                                }
                            }
                        };
                    }),
                    //spellcheck
                    spellcheck(() => this.spellcheck),
                    // style ---
                    EditorView.baseTheme(baseThemeSpec),
                    EditorView.lineWrapping,
                    //this.theme.of(EditorView.darkTheme.of(true)),
                    // baseTheme,
                    // language ---
                    pandocMarkdownLanguage(),
                    //throw events
                    EditorView.updateListener.of((v) => {
                        // console.log(JSON.stringify(v.state));
                        // console.log(v.state);
                        //console.log("selection set", v.selectionSet);
                        if (v.docChanged) {
                            this.events[MarkdownEditor.events.update].forEach(value => value({
                                doc: v.state.doc.toString(),
                                wordCount: v.state.facet(this.wordCount)
                            }));
                        }
                        else if (v.focusChanged) {
                            //console.log(v.view.hasFocus);
                            if (v.view.hasFocus) {
                                this.events[MarkdownEditor.events.focus].forEach(value => value({}));
                            }
                            else {
                                this.events[MarkdownEditor.events.blur].forEach(value => value({}));
                            }
                        }
                        else {
                            //console.log(v); todo what to do here
                        }
                    }),
                ],
                parent: parent,
                root: root,
            };
        };
        this.editor = new EditorView(this.makeViewConfig());
    }
    addEvents(name, ...events) {
        events.forEach(event => this.events[name].push(event));
    }
    setDoc(inputValue) {
        const currentValue = this.editor.state.doc.toString();
        if (inputValue !== currentValue) {
            if (this.editor)
                this.editor.destroy();
            this.editor = new EditorView(this.makeViewConfig(inputValue));
        }
    }
    setSpellcheck(spellcheck) {
        this.spellcheck = spellcheck;
    }
    getWordCount() {
        return this.editor.state.facet(this.wordCount);
    }
}
MarkdownEditor.events = {
    blur: "blur",
    update: "update",
    focus: "focus",
    save: "save"
};
export default MarkdownEditor;
