import { buildSimpleViewPlugin } from "./util/buildSimpleViewPlugin";
import { Tag } from "@lezer/highlight";
import { buildSimpleRegexParser } from "./util/buildSimpleRegexParser";
export const Emphasis = new Tag();
const EmphasisMark = new Tag();
let emphasis = () => {
    return [buildSimpleViewPlugin({
            tagName: "em"
        }, "Emphasis", "EmphasisMark")];
};
export let emphasisSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Emphasis", style: Emphasis },
                { name: "EmphasisMark", style: EmphasisMark }
            ],
            parser: buildSimpleRegexParser("\\*", "Emphasis", "EmphasisMark")
        }],
    language: [
        emphasis()
    ]
};
