import { NodeProp } from "@lezer/common";
export const AttributeNodeProp = new NodeProp({ perNode: true });
export class AttributeNodePropToModifiedDecorationSpec {
    constructor(str, configAttributes = {}) {
        this.classes = new Array();
        this.attributes = {};
        if (str) {
            let match;
            while (match = AttributeNodePropToModifiedDecorationSpec.regex.exec(str)) {
                if (match.groups.id) {
                    this.id = match.groups.id.substring(1);
                }
                else if (match.groups.class) {
                    this.classes.push(match.groups.class.substring(1));
                }
                else if (match.groups.simpleAtt) {
                    let [key, value] = match.groups.simpleAtt.split("=");
                    this.attributes[key] = value;
                }
                else if (match.groups.quotedAtt) {
                    let [key, value] = match.groups.quotedAtt.split("=");
                    value = value.substring(1, value.length - 1);
                    this.attributes[key] = value;
                }
                else {
                    console.error("did not find match group", match);
                }
            }
        }
        if (configAttributes.id) {
            this.id = configAttributes.id;
        }
        if (configAttributes.class) {
            this.classes.push(configAttributes.class);
        }
        if (configAttributes.classes) {
            this.classes.push(...configAttributes.classes);
        }
        if (configAttributes.attributes) {
            this.attributes = Object.assign(this.attributes, configAttributes.attributes);
        }
        if (configAttributes.tagName) {
            this.tagName = configAttributes.tagName;
        }
        this.inclusive = configAttributes.inclusive;
    }
    buildObject() {
        let modifiedDecorationSpec = {};
        if (this.id) {
            modifiedDecorationSpec.id = this.id;
        }
        if (this.classes.length > 0) {
            modifiedDecorationSpec.class = this.classes.join(" ");
        }
        if (Object.keys(this.attributes).length > 0) {
            modifiedDecorationSpec.attributes = this.attributes;
        }
        if (this.tagName) {
            modifiedDecorationSpec.tagName = this.tagName;
        }
        if (this.inclusive) {
            modifiedDecorationSpec.inclusive = this.inclusive;
        }
        //console.log(modifiedDecorationSpec);
        return modifiedDecorationSpec;
    }
}
AttributeNodePropToModifiedDecorationSpec.regex = /(?<id>#\w*)|(?<class>\.\w*)|(?<simpleAtt>\w+=\w+)|(?<quotedAtt>\w+="[^\n"]*")/gm;
