import { combineConfig, Facet } from "@codemirror/state";
import { EditorView, keymap, showPanel } from "@codemirror/view";
import elt from "crelt";
import { EventAction } from "./EventAction";
import { syntaxTree } from "@codemirror/language";
import { AttributeNodeText } from "../combind/headingsSimpleLanguage";
const menuConfigFacet = Facet.define({
    combine(configs) {
        return combineConfig(configs, {});
    }
});
class HeaderItem {
    constructor({ level, start, text }) {
        this.text = "-".repeat(level) + text;
        this.start = start;
        this.selected = false;
    }
    setSelected(selected) {
        this.selected = selected;
    }
    mapToOption(htmlView) {
        let attr = {};
        if (this.selected) {
            attr.selected = "selected";
        }
        attr.value = this.start;
        return htmlView.buildOption(attr, htmlView.phrase(this.text));
    }
    getStart() {
        return this.start;
    }
}
class HtmlView {
    constructor(view, eventAction = new EventAction(view)) {
        this.view = view;
        this.eventAction = eventAction;
    }
    clearButton() {
        return this.buildButton({ onclick: () => this.eventAction.removeMarks(), title: "clear all formatting" }, this.buildMaterialIcon("delete"));
    }
    headerButton(headerLevel = 1) {
        return this.buildButton({ onclick: () => this.eventAction.setHeader(headerLevel), title: "Header Level " + headerLevel }, this.phrase("H"), elt("sub", null, [this.phrase(String(headerLevel))]));
    }
    headerDropdown(view) {
        let attrs = {};
        attrs.onchange = function (e) {
            // @ts-ignore
            let position = e.target.value;
            let line = view.state.doc.lineAt(position);
            view.focus();
            view.dispatch({
                selection: {
                    anchor: line.from,
                    head: line.to,
                },
                scrollIntoView: true
            });
        };
        return elt("select", attrs, this.buildHeaderOptions(view));
    }
    buildHeaderOptions(view) {
        let list = [];
        syntaxTree(view.state).iterate({
            enter: (headerNode) => {
                if (headerNode.name.match(/^Heading\d$/)) {
                    let text = this.cleanMarks(headerNode?.tree?.prop(AttributeNodeText));
                    let level = Number(headerNode.name.substring(7)) - 1;
                    list.push(new HeaderItem({ text, level, start: headerNode.from }));
                }
            }
        });
        let head = view.state.selection.main.head;
        let headerItems = list.filter(value => value.getStart() <= head);
        if (headerItems.length > 0) {
            let element = headerItems.pop();
            element.setSelected(true);
        }
        if (list[0]?.getStart() !== 0) {
            list.unshift(new HeaderItem({ level: 0, start: 0, text: "" }));
        }
        let children = list.map(value => {
            return value.mapToOption(this);
        });
        return children;
    }
    buildButton(attrs = {}, ...children) {
        if (attrs?.class) {
            attrs.class += " size";
        }
        else {
            attrs.class = "size";
        }
        return elt("button", attrs, children);
    }
    phrase(phrase) { return this.view.state.phrase(phrase); }
    buildMaterialIcon(iconString) {
        return elt("span", { class: "material-icons" }, [this.phrase(iconString)]);
    }
    buildMaterialIconRotated(iconString) {
        return elt("span", { class: "material-icons rotated" }, [this.phrase(iconString)]);
    }
    startGroup(...children) {
        return HtmlView.group({ class: "start_group" }, children);
    }
    static group(attrs, children) {
        return elt("span", attrs, children);
    }
    groupItems(...children) {
        return HtmlView.group({ class: "item_group" }, children);
    }
    em(text) {
        return elt("em", null, this.phrase(text));
    }
    strong(text) {
        return elt("strong", null, this.phrase(text));
    }
    cleanMarks(string) {
        let outString = string;
        let matcher = /^\[(?<text>.*)]\(.*\)$/;
        let exec = matcher.exec(outString);
        if (exec?.groups?.text) {
            outString = exec.groups.text;
        }
        return outString;
    }
    buildOption(attrs = {}, ...children) {
        return elt("option", attrs, children);
    }
}
const menubarPanel = showPanel.of(function (view) {
    const eventAction = new EventAction(view);
    const htmlView = new HtmlView(view, eventAction);
    let headerDropdown = htmlView.headerDropdown(view);
    let dom = elt("div", null, [
        htmlView.groupItems(htmlView.buildButton({ title: "Undo", onclick: () => eventAction.undo() }, htmlView.buildMaterialIcon("undo")), htmlView.buildButton({ title: "Redo", onclick: () => eventAction.redo() }, htmlView.buildMaterialIcon("redo")), htmlView.clearButton()),
        htmlView.groupItems(// main part of the menu
        htmlView.buildButton({ title: "Italic", onclick: () => eventAction.addEmphasis() }, htmlView.buildMaterialIcon("format_italic")), htmlView.buildButton({ title: "Bold", onclick: () => eventAction.addStrongEmphasis() }, htmlView.buildMaterialIcon("format_bold")), htmlView.buildButton({ title: "Highlight", onclick: () => eventAction.addHighlighting() }, htmlView.buildMaterialIcon("border_color")), htmlView.buildButton({ title: "Strikethrough", onclick: () => eventAction.addStrikeout() }, htmlView.buildMaterialIcon("format_strikethrough")), htmlView.buildButton({ title: "Superscript", onclick: () => eventAction.addSuperscripts() }, htmlView.buildMaterialIcon("superscript")), htmlView.buildButton({ title: "SubScript", onclick: () => eventAction.addSubscripts() }, htmlView.buildMaterialIcon("subscript")), htmlView.buildButton({ title: "Underline", onclick: () => eventAction.addUnderline() }, htmlView.buildMaterialIcon("format_underlined")), htmlView.buildButton({ title: "Small Caps", onclick: () => eventAction.addSmallCaps() }, htmlView.buildMaterialIcon("text_format")), htmlView.buildButton({ title: "Verbatim", onclick: () => eventAction.addVerbatim() }, htmlView.phrase("`")), htmlView.buildButton({ title: "Comment", onclick: () => eventAction.addComment() }, htmlView.buildMaterialIcon("maps_ugc"))),
        htmlView.groupItems(// main part of the menu
        htmlView.buildButton({ title: "Bulleted List", onclick: () => eventAction.addUnorderedList() }, htmlView.buildMaterialIcon("format_list_bulleted")), htmlView.buildButton({ title: "Check List", onclick: () => eventAction.addCheckedList() }, htmlView.buildMaterialIcon("checklist")), htmlView.buildButton({ title: "Numbered List", onclick: () => eventAction.addOrderedList() }, htmlView.buildMaterialIcon("format_list_numbered")), htmlView.buildButton({ title: "Numbered Example List", onclick: () => eventAction.addNumberedExampleLists() }, htmlView.buildMaterialIcon("list_alt")), htmlView.buildButton({ title: "New Definition List", onclick: () => eventAction.addDefinitionLists() }, htmlView.buildMaterialIcon("text_snippet")), htmlView.buildButton({ title: "Indent Lines (Ctrl->)", onclick: () => eventAction.increaseIndent() }, htmlView.buildMaterialIcon("format_indent_increase")), htmlView.buildButton({ title: "Un-indent Lines (Ctrl-<)", onclick: () => eventAction.decreaseIndent() }, htmlView.buildMaterialIcon("format_indent_decrease"))),
        htmlView.groupItems(// main page
        htmlView.buildButton({ title: "Horizontal Rule", onclick: () => eventAction.addHorizontalRule() }, htmlView.buildMaterialIcon("horizontal_rule"))),
        /*
                htmlView.groupItems(// button for now but sub menu later.
                    htmlView.buildButton({title:"Table",onclick: () => eventAction.addTables()}, htmlView.buildMaterialIcon("table_chart")),
                ),
        */
        htmlView.groupItems(// sub menu now
        /*
                    htmlView.buildButton({title:"Footnote Link",onclick: () => eventAction.addFootnotes()}, htmlView.buildMaterialIcon("subdirectory_arrow_left")),
                    htmlView.buildButton({title:"Add Footnote",onclick: () => eventAction.addFootnotes()}, htmlView.buildMaterialIcon("subdirectory_arrow_left")),
        */
        htmlView.buildButton({ title: "Inline Footnote", onclick: () => eventAction.addInlineNotes() }, htmlView.buildMaterialIconRotated("mode_comment"))),
        htmlView.groupItems(// submenu with navigation
        htmlView.headerButton(1), htmlView.headerButton(2), htmlView.headerButton(3), htmlView.headerButton(4), htmlView.headerButton(5), htmlView.headerButton(6), headerDropdown),
        /*
                htmlView.groupItems(// sub menu to give more info
                    htmlView.buildButton({title:"Insert Link",onclick: () => eventAction.addLink()}, htmlView.buildMaterialIcon("add_link")),
                    htmlView.buildButton({title:"Internal Link",onclick: () => eventAction.addInternalLink()}, htmlView.phrase("InternalLink")),
                    htmlView.buildButton({title:"Reference Link",onclick: () => eventAction.addReferenceLink()}, htmlView.phrase("ReferenceLink")),
                ),
        */
        /*
                htmlView.groupItems(// sub menu to give more info
                    htmlView.buildButton({title:"Insert Image",onclick: () => eventAction.addImage()}, htmlView.buildMaterialIcon("image")),
                    htmlView.buildButton({title:"Image Reference",onclick: () => eventAction.addReferenceImage()}, htmlView.phrase("ReferenceImage")),
                ),
        */
        /*
                htmlView.groupItems(// sub menu for code and quote.
                    htmlView.buildButton({title:"Block Quote",onclick: () => eventAction.addBlockQuote()}, htmlView.phrase("blockQuote")),
                    htmlView.buildButton({title:"Inline Code",onclick: () => eventAction.addCodeInline()}, htmlView.phrase("codeInline")),
                    htmlView.buildButton({title:"Code Block",onclick: () => eventAction.addCodeBlock()}, htmlView.phrase("codeBlock")),
                    htmlView.buildButton({title:"Fenced Code Block",onclick: () => eventAction.addFencedCodeBlock()}, htmlView.phrase("FencedCodeBlock")),
                    htmlView.buildButton({title:"Fenced Lines",onclick: () => eventAction.addFencedDivs()}, htmlView.phrase("fenced_divs")),
                    htmlView.buildButton({title:"Fenced String",onclick: () => eventAction.addBracketedSpans()}, htmlView.phrase("bracketed_spans")),
                ),
        */
        /*
                htmlView.groupItems(// sub menu now
                    htmlView.buildButton({title:"Metadata Block",onclick: () => eventAction.addMetadataBlocks()}, htmlView.phrase("Metadata blocks")),
                    htmlView.buildButton({title:"YAML Metadata Block",onclick: () => eventAction.addYamlMetadataBlock()}, htmlView.phrase("YAML metadata block")),
                ),
        */
        htmlView.groupItems(// insert
        htmlView.buildButton({ title: "Insert Date", onclick: () => eventAction.insertDate() }, htmlView.buildMaterialIcon("today"))),
        // object attributes.
        //fold all
        //unfold all
        // open find
        // open goto
        htmlView.groupItems(htmlView.buildButton({ title: "Save", onclick: () => view.state.facet(menuConfigFacet).saveEvent(view) }, htmlView.buildMaterialIcon("save")))
    ]);
    return {
        dom,
        top: true,
        update(update) {
            headerDropdown.replaceChildren();
            htmlView.buildHeaderOptions(update.view)
                .forEach(value => headerDropdown.appendChild(value));
        }
    };
});
const baseTheme = EditorView.baseTheme({
    ".cm-panel.cm-search": {
        padding: "2px 6px 4px",
        position: "relative",
        "& [name=close]": {
            position: "absolute",
            right: "4px",
            backgroundColor: "inherit",
            border: "none",
            padding: 0,
            margin: 0
        },
        "& input, & button, & label": {
            margin: ".2em .6em .2em 0"
        },
        "& input[type=checkbox]": {
            marginRight: ".2em"
        },
        "& label": {
            whiteSpace: "pre"
        }
    },
    "&light .cm-searchMatch": { backgroundColor: "#ffff0054" },
    "&dark .cm-searchMatch": { backgroundColor: "#00ffff8a" },
    "&light .cm-searchMatch-selected": { backgroundColor: "#ff6a0054" },
    "&dark .cm-searchMatch-selected": { backgroundColor: "#ff00ff8a" },
    "& input.cm-checkbox[type=checkbox]": {
        display: "none"
    },
    "& span.cm-checkbox": {
        backgroundColor: "var(--checkbox-background-color, #373740)",
        color: "var(--checkbox-color, #FFF)",
        borderRadius: "4px",
        fontWeight: "200",
        letterSpacing: "1px",
        textAlign: "center",
        padding: "10px",
        cursor: "pointer",
        margin: "0 auto",
    },
    "& input.cm-checkbox[type=checkbox]:checked + span.cm-checkbox": {
        backgroundColor: "var(--checkbox-checked-background-color, #ff5847)",
        color: "var(--checkbox-checked-color, white)",
    },
    "& .item_group": {
        display: "inline-block",
        padding: "2px"
    },
    "& button.size": {
        fontSize: "16px"
    }
});
export const menubarKeymap = [
    { key: "Mod-D", run: (view) => {
            new EventAction(view).insertDate();
            return true;
        }, preventDefault: true },
];
const menuExtensions = [
    baseTheme,
    menubarPanel,
    keymap.of(menubarKeymap)
];
export function menubar(config) {
    return config ? [menuConfigFacet.of(config), menuExtensions] : menuExtensions;
}
