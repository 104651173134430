import { Tag } from "@lezer/highlight";
import { buildSimpleViewPlugin } from "./util/buildSimpleViewPlugin";
import { EditorView } from "codemirror";
const Verbatim = new Tag();
const VerbatimMark = new Tag();
export let verbatim = () => {
    return [buildSimpleViewPlugin({
            class: "verbatim"
        }, "Verbatim", "VerbatimMark"),
        EditorView.baseTheme({
            ".verbatim": {}
        })];
};
const regExp = /(?<pre>[\s^])((?<verbatim1>(?<mark1>`)[^`]+(?<mark2>`))|(?<verbatim2>(?<mark3>``).+(?<mark4>``)))/gm;
function buildRanges(context, index, match, pre, verbatim, mark1, mark2) {
    let verbatimRange, markRange1, markRange2;
    verbatimRange = context.buildRange({
        from: index + pre.length,
        length: verbatim.length
    });
    markRange1 = context.buildRange({
        from: index + pre.length,
        length: mark1.length
    });
    markRange2 = context.buildRange({
        from: index + match.length - mark2.length,
        length: mark2.length
    });
    return [verbatimRange, markRange1, markRange2];
}
export let verbatimSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Verbatim", style: Verbatim },
                { name: "VerbatimMark", style: VerbatimMark }
            ],
            parser: context => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    let verbatimRange, markRange1, markRange2;
                    if (match.groups.verbatim1) {
                        [verbatimRange, markRange1, markRange2] = buildRanges(context, match.index, match[0], match.groups.pre, match.groups.verbatim1, match.groups.mark1, match.groups.mark2);
                    }
                    else if (match.groups.verbatim2) {
                        [verbatimRange, markRange1, markRange2] = buildRanges(context, match.index, match[0], match.groups.pre, match.groups.verbatim2, match.groups.mark3, match.groups.mark4);
                    }
                    return context.createTree({
                        name: "Verbatim",
                        children: [
                            context.createTree({ name: "VerbatimMark", range: markRange1 }),
                            context.createTree({ name: "VerbatimMark", range: markRange2 })
                        ],
                        range: verbatimRange
                    });
                });
            }
        }],
    language: [
    //verbatim()// remove only for building.
    ]
};
