import { Tag } from "@lezer/highlight";
import DecorationHolder from "./util/DecorationHolder";
import { Decoration, ViewPlugin } from "@codemirror/view";
import { syntaxTree } from "@codemirror/language";
const EmphasisStrongEmphasisMark = new Tag();
let emphasisStrongEmphasisMarks = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.headings(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.headings(update.view);
            }
            headings(view) {
                // @ts-ignore
                let items = [];
                let invisible = Decoration.mark({ class: "cm-mark" });
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (outerNode) => {
                            if (outerNode.name === "Emphasis") {
                                syntaxTree(view.state).iterate({
                                    from: outerNode.from,
                                    to: outerNode.to,
                                    enter: (node) => {
                                        if (node.name === "EmphasisStrongEmphasisMark") {
                                            items.push(new DecorationHolder(invisible, node.from, node.to));
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets);
            }
        }, {
            decorations: v => v.decorations
        })];
};
const regExp = /(?<preObj>^|\s)(?<mark1>\*\*\*)\w.*\w(?<mark2>\*\*\*)/g;
export let EmphasisStrongEmphasisSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "EmphasisStrongEmphasisMark", style: EmphasisStrongEmphasisMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    return context.createTree({
                        name: "Emphasis",
                        children: [
                            context.createTree({
                                name: "StrongEmphasis",
                                children: [
                                    context.createTree({
                                        name: "EmphasisStrongEmphasisMark",
                                        range: context.buildRange({
                                            from: match.index + match.groups.preObj.length,
                                            length: match.groups.mark1.length
                                        })
                                    }),
                                    context.createTree({
                                        name: "EmphasisStrongEmphasisMark",
                                        range: context.buildRange({
                                            from: match.index + match[0].length - match.groups.mark2.length,
                                            length: match.groups.mark2.length
                                        })
                                    })
                                ],
                                range: context.buildRange({
                                    from: match.index + match.groups.preObj.length,
                                    length: match[0].length - match.groups.preObj.length
                                })
                            })
                        ],
                        range: context.buildRange({
                            from: match.index + match.groups.preObj.length,
                            length: match[0].length - match.groups.preObj.length
                        })
                    });
                });
            }
        }],
    language: [
        emphasisStrongEmphasisMarks()
    ]
};
