import { Tag } from "@lezer/highlight";
import { buildSimpleViewPlugin } from "./util/buildSimpleViewPlugin";
import { buildSimpleRegexParser } from "./util/buildSimpleRegexParser";
const Subscript = new Tag();
const SubscriptMark = new Tag();
export let subscript = () => {
    return [buildSimpleViewPlugin({
            tagName: "sub"
        }, "Subscript", "SubscriptMark")];
};
export let subscriptSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Subscript", style: Subscript },
                { name: "SubscriptMark", style: SubscriptMark }
            ],
            parser: buildSimpleRegexParser("~", "Subscript", "SubscriptMark")
        }],
    language: [
        subscript()
    ]
};
