import { strikethroughSimpleLanguage } from "../combind/strikethroughSimpleLanguage";
import { superscriptSimpleLanguage } from "../combind/superscriptSimpleLanguage";
import { subscriptSimpleLanguage } from "../combind/subscriptSimpleLanguage";
import { simpleLanguage } from "./simpleLanguage";
import { bracketedSpanSimpleLanguage } from "../combind/bracketedSpanSimpleLanguage";
import { emphasisSimpleLanguage } from "../combind/emphasisSimpleLanguage";
import { StrongEmphasisSimpleLanguage } from "../combind/strongEmphasisSimpleLanguage";
import { EmphasisStrongEmphasisSimpleLanguage } from "../combind/emphasisStrongEmphasisSimpleLanguage";
import { SubscriptStrikethroughSimpleLanguage } from "../combind/subscriptStrikethroughSimpleLanguage";
import { htmlCommentSimpleLanguage } from "../combind/htmlCommentSimpleLanguage";
import { horizontalRuleSimpleLanguage } from "../combind/horizontalRuleSimpleLanguage";
import { headingSimpleLanguage } from "../combind/headingsSimpleLanguage";
import { verbatimSimpleLanguage } from "../combind/verbatimSimpleLanguage";
import { inlineFootnoteSimpleLanguage } from "../combind/foootnoteInlineSimpleLanguage";
import { bulletListSimpleLanguage } from "../combind/bulletListSimpleLanguage";
import { numberedListSimpleLanguage } from "../combind/numberListSimpleLanguage";
import { exampleSimpleLanguage } from "../combind/exampleListSimpleLanguage";
import { definitionListSimpleLanguage } from "../combind/definitionListSimpleLanguage";
import { InlineLinkSimpleLanguage } from "../combind/inlineLinkSimpleLanguage";
import { InlineImageSimpleLanguage } from "../combind/inlineImageSimpleLanguage";
import { todoListSimpleLanguage } from "../combind/todoListSimpleLanguage";
let pandocMarkdownLanguage = () => simpleLanguage({
    name: "pandocMarkdown",
    extensions: [
        strikethroughSimpleLanguage,
        superscriptSimpleLanguage,
        subscriptSimpleLanguage,
        bracketedSpanSimpleLanguage,
        emphasisSimpleLanguage,
        StrongEmphasisSimpleLanguage,
        EmphasisStrongEmphasisSimpleLanguage,
        SubscriptStrikethroughSimpleLanguage,
        htmlCommentSimpleLanguage,
        horizontalRuleSimpleLanguage,
        headingSimpleLanguage,
        verbatimSimpleLanguage,
        inlineFootnoteSimpleLanguage,
        bulletListSimpleLanguage,
        numberedListSimpleLanguage,
        exampleSimpleLanguage,
        definitionListSimpleLanguage,
        InlineLinkSimpleLanguage,
        InlineImageSimpleLanguage,
        todoListSimpleLanguage
    ],
});
export default pandocMarkdownLanguage;
