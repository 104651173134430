export default class Range {
    constructor({ from, to, length }) {
        if (from === undefined) {
            throw "from must be defined.";
        }
        if (to !== undefined && length !== undefined) {
            throw "only length or to should be defined.";
        }
        if (to === undefined && length === undefined) {
            throw "to or length must be defined.";
        }
        this._from = from;
        this._to = to;
        this._length = length;
    }
    get from() {
        return this._from;
    }
    get to() {
        if (this._to) {
            return this._to;
        }
        return this._from + this._length;
    }
    get length() {
        if (this._length) {
            return this._length;
        }
        return this._to - this._from;
    }
    compareTo(other) {
        if (other.from === this.from) {
            return this.to - other.to;
        }
        return this.from - other.from;
    }
    inRange(pos) {
        return (this.from < pos && pos < this.to);
    }
}
