export function buildSimpleRegexParser(mark, nodeName, nodeMarkName) {
    return buildSimpleRegexParserStartEnd(mark, mark, nodeName, nodeMarkName);
}
export function buildSimpleRegexParserStartEnd(markStart, markEnd, nodeName, nodeMarkName) {
    const regExp = new RegExp(`(?<preObj>^|\\s)(?<mark1>${markStart})\\w.*\\w(?<mark2>${markEnd})`, "g");
    return (context) => {
        let matchArray = context.match(regExp);
        return matchArray.map((match) => {
            return context.createTree({
                name: nodeName,
                children: [
                    context.createTree({
                        name: nodeMarkName,
                        range: context.buildRange({
                            from: match.index + match.groups.preObj.length,
                            length: match.groups.mark1.length
                        })
                    }),
                    context.createTree({
                        name: nodeMarkName,
                        range: context.buildRange({
                            from: match.index + match[0].length - match.groups.mark2.length,
                            length: match.groups.mark2.length
                        })
                    })
                ],
                startPosition: match.index + match.groups.preObj.length,
                length: match[0].length - match.groups.preObj.length
            });
        });
    };
}
