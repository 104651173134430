import { EventAction } from "./EventAction";
import { moveLineDown, moveLineUp } from "@codemirror/commands";
const indentLess = (view) => {
    if (view.state.readOnly)
        return false;
    let eventAction = new EventAction(view);
    eventAction.decreaseIndent();
    return true;
};
const indentMore = (view) => {
    if (view.state.readOnly)
        return false;
    let eventAction = new EventAction(view);
    eventAction.increaseIndent();
    return true;
};
const insertTab = (view) => {
    if (view.state.readOnly)
        return false;
    let eventAction = new EventAction(view);
    eventAction.insertTab();
    return true;
};
export const allowTab = [{ key: "Tab", run: insertTab },
    { key: "Mod->", run: indentMore },
    { key: "Mod-<", run: indentLess },
    { key: "Mod-Shift-ArrowUp", run: moveLineUp },
    { key: "Mod-Shift-ArrowDown", run: moveLineDown }];
