import { Tag } from "@lezer/highlight";
import { Decoration, EditorView, gutter, GutterMarker, ViewPlugin } from "@codemirror/view";
import { NodeProp } from "@lezer/common";
import DecorationHolder from "./util/DecorationHolder";
import { foldService, syntaxTree } from "@codemirror/language";
import { AttributeNodeProp, AttributeNodePropToModifiedDecorationSpec } from "./util/attributeNodeProp";
const Heading1 = new Tag();
const Heading2 = new Tag();
const Heading3 = new Tag();
const Heading4 = new Tag();
const Heading5 = new Tag();
const Heading6 = new Tag();
const HeadingMark = new Tag();
export const AttributeNodeText = new NodeProp({ perNode: true });
class HeadingGutterMarker extends GutterMarker {
    constructor(markText) {
        super();
        this.markText = markText;
    }
    toDOM() { return document.createTextNode(this.markText); }
}
const emptyMarker = new class extends GutterMarker {
    toDOM() { return document.createTextNode(""); }
};
const markerNodes = {
    "Heading1": new HeadingGutterMarker("h1"),
    "Heading2": new HeadingGutterMarker("h2"),
    "Heading3": new HeadingGutterMarker("h3"),
    "Heading4": new HeadingGutterMarker("h4"),
    "Heading5": new HeadingGutterMarker("h5"),
    "Heading6": new HeadingGutterMarker("h6"),
};
let heading = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.headings(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.headings(update.view);
            }
            headings(view) {
                // @ts-ignore
                let items = [];
                let invisible = Decoration.mark({ class: "cm-mark" });
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (headerNode) => {
                            //console.log(headerNode.name, headerNode.from, headerNode.to);// use this to print out tags.
                            if (headerNode.name.match(/^Heading\d$/)) {
                                // @ts-ignore
                                let deco = Decoration.mark(new AttributeNodePropToModifiedDecorationSpec(headerNode?.tree?.prop(AttributeNodeProp), {
                                    classes: ["heading" + headerNode.name.substring(7), "header"],
                                    //inclusive:true
                                }).buildObject());
                                items.push(new DecorationHolder(deco, headerNode.from, headerNode.to));
                                syntaxTree(view.state).iterate({
                                    from: headerNode.from,
                                    to: headerNode.to,
                                    enter: (node) => {
                                        if (node.name === "HeadingMark") {
                                            items.push(new DecorationHolder(invisible, node.from, node.to));
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets, true);
            }
        }, {
            decorations: v => v.decorations
        }),
        //todo should this gutter help be somewhere else?
        gutter({
            lineMarker(view, line) {
                let found = null;
                syntaxTree(view.state).iterate({
                    from: line.from,
                    to: line.to,
                    enter: (node) => {
                        if (markerNodes.hasOwnProperty(node.name)) {
                            found = markerNodes[node.name];
                        }
                        else {
                            console.log(node.name, line.from, line.to);
                        }
                    }
                });
                return found;
            },
            initialSpacer: () => emptyMarker
        }),
        EditorView.baseTheme({
            '.header': {
                fontWeight: 'bold'
            },
            ".heading1": {
                fontSize: '2.2rem',
            },
            ".heading2": {
                fontSize: '1.8rem',
            },
            ".heading3": {
                fontSize: '1.4rem',
            },
            ".heading4": {
                fontSize: '1.2rem',
            },
            ".heading5": {
                fontSize: '1rem',
            },
            ".heading6": {
                fontSize: '0.8rem',
            }
        }),
        foldService.of((state, start, end) => {
            let node = syntaxTree(state).resolveInner(start + 1, -1);
            while (node) {
                if (node.from < start)
                    break;
                if (!node.type.name.match(/^Heading\d$/)) {
                    node = node.parent;
                    continue;
                }
                let last = node;
                while (true) {
                    let next = last.nextSibling;
                    if (!next || next.type.name === node.name ||
                        (next.type.name.match(/^Heading\d$/) && node.name > next.type.name)) {
                        last = next;
                        break;
                    }
                    last = next;
                }
                if (last) {
                    let upto = last.from - 1;
                    if (upto > end) {
                        return { from: end, to: upto };
                    }
                }
                else {
                    return { from: end, to: state.doc.length };
                }
                node = node.parent;
            }
            return null;
        })];
};
const regExp = /^(?<markStart>#{1,6} )(?<text>[^\n}]*)(?<markEnd>{(?<info>[^}]*)})?$/gm;
export let headingSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Heading1", style: Heading1 },
                { name: "Heading2", style: Heading2 },
                { name: "Heading3", style: Heading3 },
                { name: "Heading4", style: Heading4 },
                { name: "Heading5", style: Heading5 },
                { name: "Heading6", style: Heading6 },
                { name: "HeadingMark", style: HeadingMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    const children = [context.createTree({
                            name: "HeadingMark",
                            range: context.buildRange({ from: match.index, length: match.groups.markStart.length })
                        })];
                    const props = [];
                    if (match.groups.markEnd) {
                        children.push(context.createTree({
                            name: "HeadingMark",
                            range: context.buildRange({
                                from: match.index + match[0].length - match.groups.markEnd.length,
                                length: match.groups.markEnd.length
                            })
                        }));
                        if (match.groups.info) {
                            props.push([AttributeNodeProp, match.groups.info]);
                        }
                    }
                    if (match.groups.text) {
                        props.push([AttributeNodeText, match.groups.text]);
                    }
                    return context.createTree({
                        name: "Heading" + (match.groups.markStart.length - 1), children: children,
                        range: context.buildRange({
                            from: match.index, length: match[0].length
                        }),
                        props: props
                    });
                });
            }
        }],
    language: [
        heading()
    ]
};
