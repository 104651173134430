import { Tag } from "@lezer/highlight";
import { Decoration, ViewPlugin, WidgetType } from "@codemirror/view";
import { NodeProp } from "@lezer/common";
import DecorationHolder from "./util/DecorationHolder";
import { syntaxTree } from "@codemirror/language";
const InlineLink = new Tag();
const InlineLinkMark = new Tag();
const InlineLinkUrl = new NodeProp({ perNode: true });
class BlockQuoteBorderWidget extends WidgetType {
    constructor(url) {
        super();
        this.url = url;
    }
    // noinspection JSUnusedGlobalSymbols
    toDOM() {
        const anchor = document.createElement('a');
        anchor.classList.add("material-icons");
        anchor.href = this.url;
        anchor.target = "_blank";
        anchor.textContent = "link";
        return anchor;
    }
}
let inlineLink = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.headings(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.headings(update.view);
            }
            headings(view) {
                // @ts-ignore
                let items = [];
                let invisible = Decoration.mark({ class: "cm-mark" });
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (outerNode) => {
                            if (outerNode.name === "InlineLink") {
                                const url = outerNode?.tree?.prop(InlineLinkUrl);
                                syntaxTree(view.state).iterate({
                                    from: outerNode.from,
                                    to: outerNode.to,
                                    enter: (node) => {
                                        if (node.name === "InlineLinkMark") {
                                            items.push(new DecorationHolder(invisible, node.from, node.to));
                                        }
                                    }
                                });
                                items.push(new DecorationHolder(Decoration.mark({
                                    tagName: "a",
                                    attributes: {
                                        href: url
                                    },
                                }), outerNode.from, outerNode.to));
                                items.push(new DecorationHolder(Decoration.widget({
                                    widget: new BlockQuoteBorderWidget(url)
                                }), outerNode.to, outerNode.to));
                            }
                        }
                    });
                }
                let widgets = [];
                items //.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets, true);
            }
        }, {
            decorations: v => v.decorations
        })];
};
const regExp = /(?<pre>[^!])(?<mark1>\[)[^\]]*(?<mark2>]\()(?<url>[^)]+)(?<mark3>\))/gm;
export let InlineLinkSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "InlineLink", style: InlineLink },
                { name: "InlineLinkMark", style: InlineLinkMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    return context.createTree({
                        name: "InlineLink",
                        children: [
                            context.createTree({
                                name: "InlineLinkMark",
                                range: context.buildRange({
                                    from: match.index + 1,
                                    length: match.groups.mark1.length
                                })
                            }),
                            context.createTree({
                                name: "InlineLinkMark",
                                range: context.buildRange({
                                    from: match.index + 1 + match[0].length - (1 + match.groups.mark3.length + match.groups.mark2.length + match.groups.url.length),
                                    length: match.groups.mark3.length + match.groups.mark2.length + match.groups.url.length
                                })
                            })
                        ],
                        range: context.buildRange({
                            from: match.index + 1,
                            length: match[0].length - 1
                        }),
                        props: [[InlineLinkUrl, match.groups.url]]
                    });
                });
            }
        }],
    language: [
        inlineLink()
    ]
};
