import { StateField } from "@codemirror/state";
import { Decoration, EditorView, hoverTooltip, ViewPlugin } from "@codemirror/view";
import { SearchQuery } from "./search";
import MyRange from "../combind/util/Range";
import DecorationHolder from "../combind/util/DecorationHolder";
class SpellCheckState {
    constructor({ badWords } = { badWords: [] }) {
        this.decorations = [];
        this.badWordlist = badWords;
    }
    getDecorations({ state, ranges }) {
        this.decorations = [];
        this.badWords = [];
        if (!state) {
            return [];
        }
        if (ranges.length <= 0) {
            return [];
        }
        for (const badWord of this.badWordlist) {
            if (badWord.original && badWord.guesses?.length > 0) {
                const query = new SearchQuery({ search: badWord.original, caseSensitive: true, wholeWord: true });
                for (const range of ranges) {
                    let cursor = query.getCursor(state, range.from, range.to);
                    let result = cursor.next();
                    while (!result.done) {
                        let range = new MyRange({
                            from: result.value.from,
                            to: result.value.to
                        });
                        this.decorations.push(new DecorationHolder(Decoration.mark({ class: "spellcheck-marker" }), range.from, range.to));
                        this.badWords.push({
                            range: range,
                            badWord
                        });
                        result = cursor.next();
                    }
                }
            }
        }
        return this.decorations;
    }
    findByPosition(pos) {
        return this.badWords.find(value => value.range.inRange(pos));
    }
}
class MarkerHolder {
    constructor(gutterMarker, range, state) {
        this.gutterMarker = gutterMarker;
        this.range = range;
        this.state = state;
    }
    buildRange() {
        return this.gutterMarker.range(this.range.from, this.range.to);
    }
    compareTo(other) {
        return this.range.compareTo(other.range);
    }
}
class SpellCheckToolTip {
    constructor(pos, { range, badWord }) {
        this.pos = pos;
        this.end = range.to;
        this.range = range;
        this.badWord = badWord;
    }
    create(view) {
        const dom = document.createElement("div");
        dom.classList.add("spellcheck-tooltip");
        for (const guess of this.badWord.guesses) {
            const child = document.createElement("div");
            child.textContent = guess;
            child.classList.add("spellcheck-tooltip-guess");
            child.onclick = () => {
                view.dispatch({
                    changes: {
                        to: this.range.to,
                        from: this.range.from,
                        insert: guess
                    },
                    selection: {
                        anchor: this.range.from,
                        head: this.range.from
                    }
                });
                view.focus();
            };
            dom.appendChild(child);
        }
        return { dom };
    }
}
export function spellcheck(getSpellcheckData) {
    let badWordsCount = null;
    return [
        /*
                EditorView.contentAttributes.of((view) => {
                    view.contentDOM.spellcheck = true;
                    return {};
                }),
        */
        StateField.define({
            create() {
                return new SpellCheckState();
            },
            update(value, tr) {
                const badWords = getSpellcheckData();
                if (!badWords)
                    return new SpellCheckState();
                if (!tr.docChanged && badWordsCount == badWords.length)
                    return value;
                badWordsCount = badWords.length;
                return new SpellCheckState({ badWords });
            },
            provide: f => [
                //showPanel.from(f, val => val.buildPanel()),
                ViewPlugin.fromClass(class {
                    constructor(view) {
                        this.decorations = this.headings(view);
                    }
                    update(update) {
                        if (update.docChanged || update.viewportChanged || update.selectionSet)
                            this.decorations = this.headings(update.view);
                    }
                    headings(view) {
                        const spellCheckState = view.state.field(f, false);
                        // @ts-ignore
                        let items = spellCheckState.getDecorations({ ranges: view.visibleRanges, state: view.state });
                        let widgets = [];
                        items.sort((a, b) => a.compareTo(b))
                            .forEach(value => widgets.push(value.buildRange()));
                        return Decoration.set(widgets);
                    }
                }, {
                    decorations: v => v.decorations
                }),
                hoverTooltip((view, pos) => {
                    const spellCheckState = view.state.field(f, false);
                    const item = spellCheckState.findByPosition(pos);
                    if (!item)
                        return null;
                    return new SpellCheckToolTip(pos, item);
                }, { hoverTime: 100, hideOnChange: "touch" })
            ]
        }),
        EditorView.baseTheme({
            "& .spellcheck-marker": {
                "text-decoration": "var(--spellcheck-marker-color, red) wavy underline"
            },
            "& .spellcheck-tooltip": {
                backgroundColor: "var(--background-color, lightgray)",
                maxHeight: "500px",
                overflow: "scroll",
                paddingTop: "10px",
                paddingBottom: "10px",
            },
            "& .cm-tooltip-hover": {
                animation: "fadeIn .5s"
            },
            "& .spellcheck-tooltip-guess:hover": {
                backgroundColor: "var(--hover-mask-color, rgba(0, 0, 0, 0.1))",
            },
            "& .spellcheck-tooltip-guess": {
                padding: "5px",
                paddingRight: "20px",
                paddingLeft: "10px"
            },
            "@keyframes fadeIn": {
                "0%": { opacity: "0" },
                "100%": { opacity: "1" }
            }
        }),
    ];
}
