import { ObjectTree } from "./ObjectTree";
import Range from "../combind/util/Range";
export class ParserContext {
    constructor({ string, nodes }) {
        this.string = string;
        this.nodes = nodes;
    }
    match(regExp) {
        let array = new Array();
        let item;
        while (item = regExp.exec(this.string)) {
            array.push(item);
        }
        return array;
    }
    buildRange(rangeConfig) {
        return new Range(rangeConfig);
    }
    createTree({ name, children = [], startPosition, length, range, props }) {
        if (!name) {
            throw "unable to create object with out name";
        }
        let builtRange;
        if (range !== undefined) {
            builtRange = range;
        }
        else if (length !== undefined && startPosition !== undefined) {
            builtRange = new Range({ from: startPosition, length });
        }
        else {
            throw "cannot have null range or build a range with out start position and length";
        }
        return new ObjectTree(this.nodes.get(name).getNodeType(), builtRange, children, props);
    }
}
