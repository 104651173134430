export default class DecorationHolder {
    constructor(decoration, from, to, widget) {
        this.decoration = decoration;
        this.from = from;
        this.to = to;
        this.widget = widget;
    }
    buildRange() {
        if (this.widget) {
            return this.decoration.range(this.to);
        }
        return this.decoration.range(this.from, this.to);
    }
    compareTo(other) {
        if (other.from === this.from) {
            return this.to - other.to;
        }
        return this.from - other.from;
    }
}
