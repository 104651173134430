import { Tag } from "@lezer/highlight";
import { buildSimpleViewPlugin } from "./util/buildSimpleViewPlugin";
import { buildSimpleRegexParser } from "./util/buildSimpleRegexParser";
const Superscript = new Tag();
const SuperscriptMark = new Tag();
export let superscript = () => {
    return [buildSimpleViewPlugin({
            tagName: "sup"
        }, "Superscript", "SuperscriptMark")];
};
export let superscriptSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Superscript", style: Superscript },
                { name: "SuperscriptMark", style: SuperscriptMark }
            ],
            parser: buildSimpleRegexParser("\\^", "Superscript", "SuperscriptMark")
        }],
    language: [
        superscript()
    ]
};
